import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DayPicker, DayModifiers } from 'react-day-picker';
import _ from 'lodash';
import classNames from 'classnames';
import AccordionButton, { AccordionButtonRef } from '../AccordionButton/AccordionButton';
import DateIcon from '../../images/date.svg';

import './style.scss';
import filterActions from '../../actions/filterActions';
import useReduxKey from '../../hooks/useReduxKey';
import { TrackingEventEnum } from '../../utils/analytics';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { CalendarContext } from '../../contexts/CalendarProvider';
import useAnalytics from '../../hooks/useAnalytics';
import { Configuration } from '../../reducers/configuration';
import { format } from 'date-fns';
import { formatDateTime } from '../DateTime';

type DatePickerProps = {
  disabled: boolean;
  showDetailsOnStart: boolean;
}

interface BeforeAfterModifier {
  after: Date;
  before: Date;
}

export default ({ disabled, showDetailsOnStart }: DatePickerProps): JSX.Element => {
  const configuration = useReduxKey<Configuration>('configuration');
  const { localeObjects } = useReduxKey('configuration');
  const buttonRef = useRef<AccordionButtonRef>();
  const { t, i18n } = useTranslation();
  const { trackEvent } = useAnalytics();
  const { setStartDate } = filterActions();

  const {
    month,
    setMonth,
    days,
    selectedDay,
    setSelectedDay,
    isFetchingFor,
  } = useContext(CalendarContext);

  useEffect(() => {
    buttonRef.current.setOpen(showDetailsOnStart);
    if (showDetailsOnStart) {
      trackEvent(TrackingEventEnum.SCHEDULE_DATE);
    } 
  }, [showDetailsOnStart]);

  function handleOnDayClick(day: Date, { disabled: dayDisabled }: DayModifiers) {
    if (dayDisabled) {
      return;
    }
    setSelectedDay(day);

    const ymd = dayjs(day).format('YYYY-MM-DD');
    trackEvent(TrackingEventEnum.SCHEDULE_DATE_SELECTED, { date: ymd });
    setStartDate(ymd);

    buttonRef.current.setOpen(false);
  }

  const calendarStartDate = useMemo(() => (
    configuration.calendarStartDate !== null
      ? dayjs(configuration?.calendarStartDate, 'YYYY-MM-DD')
      : undefined
  ), [configuration.calendarStartDate]);

  const calendarEndDate = useMemo(() => (
    configuration.calendarEndDate !== null
      ? dayjs(configuration?.calendarEndDate, 'YYYY-MM-DD')
      : undefined
  ), []);

  const [disabledDays, setDisabledDays] = useState<BeforeAfterModifier>({
    after: undefined,
    before: new Date(),
  });

  useEffect(() => {
    let initialMonth: Date = new Date();
    const newDisabledDays: BeforeAfterModifier = {
      ...disabledDays,
    };

    if (calendarStartDate !== undefined) {
      if (calendarStartDate.isAfter(disabledDays.before, 'day')) {
        newDisabledDays.before = calendarStartDate.toDate();
      }

      if (calendarStartDate.isAfter(month, 'day')) {
        initialMonth = calendarStartDate.toDate();
      }
    }

    if (calendarEndDate !== undefined) {
      if (disabledDays.after === undefined || calendarEndDate.isBefore(disabledDays.after, 'day')) {
        newDisabledDays.after = calendarEndDate.toDate();
      }

      if (calendarEndDate.isBefore(month, 'day')) {
        initialMonth = calendarEndDate.toDate();
      }
    }

    if (disabledDays.after !== undefined && dayjs(disabledDays.after).isBefore(disabledDays.before, 'day')) {
      // Otherwise it will still be enabled. Seems like it is a bug with react-day-picker.
      newDisabledDays.after = undefined;
    }

    setDisabledDays(newDisabledDays);
    setMonth(initialMonth);
  }, [configuration.maxDaysInAdvance, calendarStartDate, calendarEndDate]);

  const disabledWeekdays = _.range(7)
    .filter((i) => !configuration.enabledCalendarDays.includes(i));

  const unavailableDays = useMemo<Date[]>(() => Object
    .entries(days)
    .filter(([, { available }]) => !available)
    .map(([date]) => (
      dayjs(date, 'YYYY-MM-DD').toDate()
    )), [days]);

  const nextMonth = useMemo(() => (
    dayjs(month).add(1, 'month').toDate()
  ), [month]);

  const previousMonth = useMemo(() => {
    const previous = dayjs(month).subtract(1, 'month');
    if (previous.isBefore(dayjs(), 'month')) {
      return null;
    }

    return previous.toDate();
  }, [month]);

  return (
    <div className="date-container">
      <AccordionButton
        ref={buttonRef}
        label={
          selectedDay === undefined
            ? t('which_date')
            : `${formatDateTime(dayjs(selectedDay), 'date-long', localeObjects , i18n.language)}`
        }
        icon={DateIcon}
        disabled={disabled}
      >
        <div className="datepicker-custom-header">
          <span
            className={classNames(
              'datepicker-custom-header-button',
              'datepicker-custom-header-button--prev', {
                'datepicker-custom-header-button--disabled': previousMonth === null,
              },
            )}
            onClick={() => previousMonth !== null && setMonth(previousMonth)}
          />
          <div className="datepicker-custom-header-month">
            {`${formatDateTime(dayjs(month), 'month-year', localeObjects , i18n.language)}`}
          </div>
          <span
            className={classNames(
              'datepicker-custom-header-button',
              'datepicker-custom-header-button--next', {
                'datepicker-custom-header-button--disabled': nextMonth === null,
              },
            )}
            onClick={() => nextMonth !== null && setMonth(nextMonth)}
          />
        </div>
        {month && isFetchingFor(month)
          ? (
            <LoadingSpinner />
          )
          : (
            <DayPicker
              key={i18n.language}
              locale={localeObjects[i18n.language] as Locale}
              components={{
                Caption: () => null,
              }}
              formatters={
                {
                  formatWeekdayName: (weekday) => {
                    return format(weekday, 'eee', { locale: localeObjects[i18n.language] });
                }
              }
              }
              onDayClick={handleOnDayClick}
              month={month}
              fromMonth={calendarStartDate !== undefined ? calendarStartDate.toDate() : new Date()}
              toMonth={calendarEndDate !== undefined ? calendarEndDate.toDate() : undefined}
              weekStartsOn={configuration.firstDayOfWeek as 0 | 1 | 2 | 3 | 4 | 5 | 6}
              selected={[selectedDay]}
              disabled={[
                disabledDays,
                {
                  dayOfWeek: disabledWeekdays,
                },
                ...unavailableDays,
              ]}
            />
          )}
      </AccordionButton>
    </div>
  );
};
