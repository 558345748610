import {
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import useReduxKey from './useReduxKey';

import timeslotActions from '../actions/timeslotActions';
import { ResourceTreeNode } from './useLocations';
import { Space, Timeslot } from '../reducers/filter';

const TIMESLOT_FORMAT = 'YYYY-MM-DD HH:mm:ssz';

export default (
  selectedLocation: ResourceTreeNode|Space,
  selectedGroupSize: number,
  selectedDate: string|Date,
  reservationType: string | null = null,
): { timeslots: Map<string, Timeslot[]>, fetchingSlots: boolean } => {
  const { fetchTimeslots, fetchCachedTimeslots } = timeslotActions();
  const { timeslots } = useReduxKey('timeslots');
  const [fetchingSlots, setFetchingSlots] = useState(false);
  const { model } = useReduxKey('configuration');

  const fetchTimeslotsEfficiently = useCallback(
    _.debounce(
      (...args) => fetchTimeslots(...args).finally(() => setFetchingSlots(false)),
      500,
    ),
    [],
  );

  useEffect(() => {
    if (selectedLocation && selectedGroupSize && selectedDate) {
      const cachedTimeslots = fetchCachedTimeslots(
        selectedLocation, selectedGroupSize, selectedDate, reservationType);
      if (cachedTimeslots === null) {
        setFetchingSlots(true);
        fetchTimeslotsEfficiently(
          selectedLocation, selectedGroupSize, selectedDate, reservationType);
      }
    }
  }, [selectedLocation, selectedGroupSize, selectedDate, reservationType]);

  const mergedTimeslots = useMemo(() => {
    const selectedDay = dayjs(selectedDate, 'YYYY-MM-DD');
    const startOfSelectedDay = selectedDay.startOf('day');
    const endOfSelectedDay = selectedDay.endOf('day');
    return timeslots
      .map((timeslot) => ({
        ...timeslot,
        // I use the d-Prefix to indicate it is a dayjs object
        dStart: dayjs.utc(timeslot.start, TIMESLOT_FORMAT),
        dEnd: dayjs.utc(timeslot.end, TIMESLOT_FORMAT),
      }))
      .filter((timeslot) => (
        timeslot.dStart.isSameOrAfter(startOfSelectedDay)
        && timeslot.dStart.isSameOrBefore(endOfSelectedDay)
        && timeslot.available
        && timeslot.dStart.isAfter(new Date())
      ))
      .reduce((accumulatedTimeslots, timeslot) => {
        const foundTimeSlot = accumulatedTimeslots.find((t) => (
          t.dStart.isSame(timeslot.dStart)
          && t.dEnd.isSame(timeslot.dEnd)
        ));

        const booking = {
          resource_id: timeslot.resource_id,
          booking_id: timeslot.id,
        };

        if (foundTimeSlot) {
          const foundResource = foundTimeSlot.spaces.find(({ resource_id: id }) => (
            id === timeslot.resource_id
          ));

          if (!foundResource) {
            foundTimeSlot.spaces.push(booking);
          }
        } else {
          accumulatedTimeslots.push({
            dStart: timeslot.dStart,
            dEnd: timeslot.dEnd,
            model,
            spaces: [booking],
          });
        }

        return accumulatedTimeslots;
      }, [])
      .sort((a, b) => {
        if (a.dStart < b.dStart) return -1;
        if (a.dStart > b.dStart) return 1;
        if (a.dEnd < b.dEnd) return -1;
        if (a.dEnd > b.dEnd) return 1;

        return 0;
      })
      .reduce((groups, timeslot) => {
        const morning = groups.get('morning');
        const afternoon = groups.get('afternoon');
        const evening = groups.get('evening');

        const localStart = timeslot.dStart.tz();
        if (localStart.hour() < 12) {
          morning.push(timeslot);
        }

        if (localStart.hour() >= 12 && localStart.hour() < 18) {
          afternoon.push(timeslot);
        }

        if (localStart.hour() >= 18) {
          evening.push(timeslot);
        }

        return new Map([
          ['morning', morning],
          ['afternoon', afternoon],
          ['evening', evening],
        ]);
      }, new Map([
        ['morning', []],
        ['afternoon', []],
        ['evening', []],
      ]));
  }, [timeslots, selectedGroupSize, selectedDate, model, reservationType]);

  return { timeslots: mergedTimeslots, fetchingSlots };
};
