import createReducer from '../utils/createReducer';
import { Nullable } from '../types';

export interface CustomFieldConfig {
  type: 'text' | 'checkbox' | 'number';
  ORDER: number;
  label: string;
  required: boolean;
  // eslint-disable-next-line camelcase
  default_value: unknown;
  // eslint-disable-next-line camelcase
  salesforce_field_name: string;
}

export interface Configuration {
  name: string;
  maxDaysInAdvance: number;
  primaryColor: string;
  businessSlug: Nullable<string>;
  pageId: Nullable<string>;
  parentOrigin: Nullable<string>;
  showCloseButton: boolean;
  model: string;
  paymentsEnabled: boolean;
  showPayments: boolean;
  showServicesStep: boolean;
  calendarStartDate: string
  calendarEndDate: string
  enabledCalendarDays: number[]
  firstDayOfWeek: number,
  usePerAttendeeFields: boolean,
  perAttendeeFields: CustomFieldConfig[],
  customFields: CustomFieldConfig[],
  showGroupSizeStep: boolean,
  userReservationTypes: Array<{
    name: string
    order: number
    enabled: boolean
    duration: number
    interval: number
    reservation_type: string
  }>
}

const INITIAL_STATE: Partial<Configuration> = {
  primaryColor: '#F000F0',
  businessSlug: null,
  pageId: null,
  parentOrigin: null,
  showCloseButton: false,
};

export default createReducer('configuration', INITIAL_STATE, {
  setConfiguration: (state, payload: Partial<Configuration>) => ({
    ...state,
    ...payload,
  }),
});
