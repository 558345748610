import * as dayjs from 'dayjs';
import createReducer from '../utils/createReducer';
import { Nullable } from '../types';
import { ResourceTreeNode } from '../hooks/useLocations';

interface TimeslotSpace {
  // eslint-disable-next-line camelcase
  resource_id: string;
  // eslint-disable-next-line camelcase
  booking_id: string;
}

export interface Timeslot {
  spaces: TimeslotSpace[];
  dStart: null | dayjs.Dayjs;
  dEnd: null | dayjs.Dayjs;
}

export type Space = Omit<ResourceTreeNode, 'children'>;

export interface FilterState {
  selectedLocation: Nullable<ResourceTreeNode>;
  groupSize: Nullable<number>;
  startDate: Nullable<Date>;
  selectedTimeSlot: Nullable<Timeslot>;
  selectedYear: Nullable<number>;
  selectedMonth: Nullable<number>;
  selectedSpace: Nullable<Space>;
  reservationType: Nullable<string>;
}

const INITIAL_STATE: FilterState = {
  selectedLocation: null,
  groupSize: null,
  startDate: null,
  selectedTimeSlot: null,
  selectedYear: null,
  selectedMonth: null,
  selectedSpace: null,
  reservationType: null,
};

export default createReducer('filterState', INITIAL_STATE, {
  selectLocation: (state, location) => ({ ...state, selectedLocation: location }),
  deselectLocation: (state) => ({ ...state, selectedLocation: null }),
  setGroupSize: (state, groupSize) => ({ ...state, groupSize }),
  setReservationType: (state, reservationType) => ({ ...state, reservationType }),
  setStartDate: (state, startDate) => ({ ...state, startDate }),
  selectTimeSlot: (state, timeSlot) => ({ ...state, selectedTimeSlot: timeSlot }),
  resetSelectedTimeSlot: (state) => ({ ...state, selectedTimeSlot: null }),
  resetStartDate: (state) => ({ ...state, startDate: null }),
  selectSpace: (state, space) => ({ ...state, selectedSpace: space }),
  resetFilterValues: () => ({ ...INITIAL_STATE }),
});
