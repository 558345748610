import { Locale } from 'date-fns';
import { PageSettings } from '../services/Booker25';

type CustomTranslationsType = Record<string, string | undefined>;

interface CustomTranslations {
    months: string[];
    formattingMonths: string[];
    weekdays: string[];
    weekdaysShort: string[];
}

interface LocaleFnsObject {
    [key: string]: {
        default: Locale;
    };
}

/**
 * Creates a map of custom Locale objects using translations from the settings
 * If a translation is missing, the base locale's value is used
 * @param {LocaleFnsObject[]} loadedLocales - Array of objects containing locale definitions
 * @param {Partial<PageSettings>} settings - PageSettings object (contains the translations)
 * @returns {Record<string, Locale>} - Map of locale codes to custom Locale objects
 */
export const createLocaleFnsObjects = (
    loadedLocales: LocaleFnsObject[],
    settings: Partial<PageSettings>
): Record<string, Locale> => {
    return loadedLocales.reduce((acc: Record<string, Locale>, localeFnsObject: LocaleFnsObject) => {
        const key = Object.keys(localeFnsObject).find(key => key.includes('dateFnsLocale'));
        if (key) {
            const languageCode = localeFnsObject[key].default.code.split('-')[0];
            const localeObject = localeFnsObject[key].default;
            const translations = settings.translations?.widget[languageCode] || {};

            const customTranslations = {
                months: getCustomTranslations(translations, [
                    'january', 'february', 'march', 'april', 'may', 'june',
                    'july', 'august', 'september', 'october', 'november', 'december'
                ], localeObject.localize.month),

                formattingMonths: getCustomTranslations(translations, [
                    'january', 'february', 'march', 'april', 'may', 'june',
                    'july', 'august', 'september', 'october', 'november', 'december'
                ], localeObject.localize.month),

                weekdays: getCustomTranslations(translations, [
                    'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'
                ], localeObject.localize.day),

                weekdaysShort: getCustomTranslations(translations, [
                    'sunday_short', 'monday_short', 'tuesday_short', 'wednesday_short',
                    'thursday_short', 'friday_short', 'saturday_short'
                ], (index) => localeObject.localize.day(index, { width: 'short' })),
            };

            const customLocale = createLocaleWithCustomTranslations(localeObject, customTranslations);
            acc[languageCode] = customLocale;
        }
        return acc;
    }, {});
};

/**
 * Create Locale object with custom translations
 * Falls back to the base locale's translations if the custom translation matches the key or is empty
 * @param {Locale} baseLocale - Locale to extend
 * @param {CustomTranslations} translations - Object containing custom translations
 * @returns {Locale} - New Locale object with custom translations
 */
const createLocaleWithCustomTranslations = (
    baseLocale: Locale,
    translations: CustomTranslations
): Locale => {
    const { months, formattingMonths, weekdays, weekdaysShort } = translations;

    return {
        ...baseLocale,
        localize: {
            ...baseLocale.localize,
            month: (index: number, options?: { width?: string, context?: string }): string => {
                const isFormattingContext = options?.context === 'formatting';
                const monthName = isFormattingContext ? formattingMonths[index] : months[index];

                return !monthName || monthName === 'months' || monthName === 'formattingMonths'
                    ? baseLocale.localize.month(index, options)
                    : monthName;
            },
            day: (index: number, options?: { width?: string }): string => {
                const isShortWidth = options?.width === 'abbreviated' || options?.width === 'short';
                const dayName = isShortWidth ? weekdaysShort[index] : weekdays[index];

                return !dayName || dayName === 'weekdays' || dayName === 'weekdaysShort'
                    ? baseLocale.localize.day(index, options)
                    : dayName;
            },
        },
    };
};

/**
 * Generates custom translations
 * Falling back to the base locale if a custom translation is missing
 * A translation is considered missing if its value is either equal to its key or is empty
 * @param {GenericTranslations} translations - Object containing custom translations
 * @param {string[]} keys - Array of keys representing the translation fields
 * @param {(index: number) => string} baseLocaleFunction - Function from the base locale used for fallback
 * @returns {string[]} - Custom translations
 */
const getCustomTranslations = (
    translations: CustomTranslationsType,
    keys: string[],
    baseLocaleFunction: (index: number, options?: any) => string
): string[] => {
    return keys.map((key, index) => {
        const translation = translations[key];
        return !translation || translation === key
            ? baseLocaleFunction(index)
            : translation;
    });
};
