import React, { useEffect, useMemo } from 'react';

import useLocations from '../../hooks/useLocations';
import useTimeslotPicker from '../../hooks/useTimeslotPicker';
import useReduxKey from '../../hooks/useReduxKey';

import CompanyHeader from '../../components/CompanyHeader/CompanyHeader';
import LocationPicker from '../../components/LocationPicker';
import GroupSize from '../../components/GroupSizePicker';
import DatePicker from '../../components/DatePicker';
import TimeSlotPicker from '../../components/TimeSlotPicker';
import StickyFooter from '../../components/StickyFooter';
import ReservationTypePicker from '../../components/ReservationTypePicker';
import filterActions from '../../actions/filterActions';
import { FilterState } from '../../reducers/filter';
import { Configuration } from '../../reducers/configuration';

export default () : JSX.Element => {
  const {
    selectedLocation,
    groupSize: selectedGroupSize,
    reservationType: selectedReservationType,
    startDate: selectedDate,
    selectedTimeSlot,
  } = useReduxKey<FilterState>('filterState');
  const { setGroupSize, setReservationType, resetSelectedTimeSlot } = filterActions();

  let showSelections = {
    location: false,
    groupSize: false,
    date: false,
    timeSlot: false,
    reservationType: false,
  };

  let disabledSelections = {
    groupSize: false,
    date: false,
    timeSlot: false,
    reservationType: false,
  };

  useEffect(() => {
    resetSelectedTimeSlot();
  }, [selectedDate, selectedReservationType]);

  if (
    selectedLocation != null
    && selectedGroupSize != null
    && selectedReservationType != null
    && selectedDate != null
    && selectedTimeSlot != null
  ) {
    showSelections = { ...showSelections, timeSlot: true };
  } else if (selectedLocation == null) {
    showSelections = {
      ...showSelections,
      location: true,
    };

    disabledSelections = {
      groupSize: true,
      reservationType: true,
      date: true,
      timeSlot: true,
    };
  } else if (selectedGroupSize == null) {
    showSelections = {
      ...showSelections,
      groupSize: true,
    };

    disabledSelections = {
      ...disabledSelections,
      reservationType: true,
      date: true,
      timeSlot: true,
    };
  } else if (selectedReservationType == null) {
    showSelections = {
      ...showSelections,
      reservationType: true,
    };

    disabledSelections = {
      ...disabledSelections,
      date: true,
      timeSlot: true,
    };
  } else if (selectedDate == null) {
    showSelections = {
      ...showSelections,
      date: true,
    };

    disabledSelections = {
      ...disabledSelections,
      timeSlot: true,
    };
  } else if (selectedTimeSlot == null) {
    showSelections = {
      ...showSelections,
      timeSlot: true,
    };
  }

  const { searchParams } = useMemo(() => new URL(window.location.href), []);
  const defaultLocation = useMemo(() => searchParams.get('resource') || undefined, [searchParams]);

  const locations = useLocations(defaultLocation);
  const timeslots = useTimeslotPicker(
    selectedLocation,
    selectedGroupSize,
    selectedDate,
    selectedReservationType,
  );

  const { showGroupSizeStep, userReservationTypes } = useReduxKey<Configuration>('configuration');

  useEffect(() => {
    if (!showGroupSizeStep) {
      setGroupSize(1);
    }
  }, [showGroupSizeStep]);

  useEffect(() => {
    if (userReservationTypes.length === 0) {
      setReservationType('-');
    }
  }, [userReservationTypes]);

  return (
    <>
      <CompanyHeader />

      <div style={{ padding: '20px', flex: '1', overflowY: 'scroll' }}>
        <LocationPicker
          locations={locations}
          showDetailsOnStart={showSelections.location}
        />
        {showGroupSizeStep && (
          <GroupSize
            disabled={disabledSelections.groupSize}
            showDetailsOnStart={showSelections.groupSize}
          />
        )}
        {userReservationTypes.length > 0 && (
          <ReservationTypePicker
            reservationTypes={userReservationTypes}
            disabled={disabledSelections.reservationType}
            showDetailsOnStart={showSelections.reservationType}
          />
        )}
        <DatePicker
          disabled={disabledSelections.date}
          showDetailsOnStart={showSelections.date}
        />
        <TimeSlotPicker
          timeslots={timeslots}
          disabled={disabledSelections.timeSlot}
          showDetailsOnStart={showSelections.timeSlot}
        />
      </div>

      <StickyFooter showDetailsOnStart={showSelections.location} />
    </>
  );
};
