/* eslint-disable camelcase */
import { useTranslation } from 'react-i18next';
import useReduxKey from '../../hooks/useReduxKey';
import './style.scss';
import DateTime from '../DateTime';
import { Nullable } from '../../types';

import PaymentSummary from '../PaymentSummary';
import { Space, Timeslot } from '../../reducers/filter';

interface ReservationCardProps {
  space: Space;
  reservation: {
    quantity: number;
  };
  selectedTimeSlot: Timeslot;
  reservationType: Nullable<string>;
}

export default ({
  space, reservation, selectedTimeSlot,
  reservationType = null,
} : ReservationCardProps): JSX.Element => {
  const {
    showLocation, showPayments,
    showGroupSizeStep, localeObjects
  } = useReduxKey('configuration');
  const { t, i18n } = useTranslation();

  const groupSizeLabel = reservation.quantity === 1
    ? t('attendee') : t('attendees');

  return (
    <>
      <div className="card-summary">
        {space.image && (
          <img src={space.image} alt={space.name} className="img-fit-cover" />
        )}
        <h3>{t('your_reservation')}</h3>
        <h2>{space.name}</h2>
        {showLocation && <p>{space.context}</p>}
        <ul>
          {showGroupSizeStep && (
            <li>
              {reservation.quantity} {groupSizeLabel}
            </li>
          )}

          {reservationType && (
          <li>
            {t(reservationType.toLowerCase().replace(/ /g,"_"), reservationType)}
          </li>
          )}
          <li><DateTime datetime={selectedTimeSlot.dStart} format="date-long" localeObjects={localeObjects} locale={i18n.language} /></li>
          <li>
            <DateTime datetime={selectedTimeSlot.dStart} format="time" localeObjects={localeObjects}  locale={i18n.language} />
            {' - '}
            <DateTime datetime={selectedTimeSlot.dEnd} format="time" localeObjects={localeObjects}  locale={i18n.language} />
          </li>
        </ul>
      </div>
      {showPayments && <PaymentSummary />}
    </>
  );
};
