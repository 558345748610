import i18next from 'i18next';

import useActions from '../hooks/useActions';
import configuration from '../reducers/configuration';
import Booker25 from '../services/Booker25';

import { LightenColor } from '../utils/colorUtil';
import loadLocale from '../utils/dayjs';
import { useContext } from 'react';
import { LanguageContext } from '../contexts/LanguageProvider';

import { createLocaleFnsObjects } from '../utils/localeWithTranslations';

export default () => {
  const { setLanguage } = useContext(LanguageContext)

  return useActions({
    fetchConfiguration: (businessSlug: string, pageId: string, apiOverride: string | null, contactId: string | null, leadId: string | null, accountId: string | null) => async (
      dispatch,
    ) => {

      const api = new Booker25(businessSlug, pageId, apiOverride);

      const {
        settings,
        name,
        ...props
      } = await api.getPage();

      const primaryColor = settings.primary || '#000000';
      const primaryFontColor = settings.primary_font_color || '#FFFFFF';
      const fullscreenBackgroundColor = settings.fullscreen_background_color || '#FFFFFF';

      Object.entries({
        '--booker25-company-primary': primaryColor,
        '--booker25-company-primary-font-color': primaryFontColor,
        '--booker25-fullscreen-background-color': fullscreenBackgroundColor,
        '--booker25-company-primary--dark': `#${LightenColor(primaryColor, -40)}`,
        '--booker25-company-primary--light': `#${LightenColor(primaryColor, 40)}`,
      })
        .forEach(([key, value]) => {
          document.documentElement.style.setProperty(key, value);
        });

      const enabledLanguages = settings.enabled_languages;

      const userDefaultLanguages = (navigator.languages ?? []).filter((lang) => {
        const languageCode = lang.split('-')[0]; // Extract the language code from xx-XX format to compare with the enabled languages
        return enabledLanguages.includes(languageCode);
      });

      const urlLanguage = new URL(window.location.href).searchParams.get('lang');
      const urlLanguages = enabledLanguages.filter((lang) => lang === urlLanguage);
      const defaultLanguage = urlLanguages[0]
        || userDefaultLanguages[0]
        || settings.default_language;

      enabledLanguages
        .filter((lang) => settings?.translations?.widget?.[lang] !== undefined)
        .forEach((lang) => {
          i18next.addResources(lang, 'translation', {
            ...(settings?.translations?.widget?.[lang] ?? {}),
            ...(settings?.translations?.objects?.[lang] ?? {}),
          });
        });

      const loadedLocales = await Promise.all(enabledLanguages.map((lang) => loadLocale(lang)));
      const localeFnsObjects = createLocaleFnsObjects(loadedLocales, settings);

      setLanguage(defaultLanguage);

      // eslint-disable-next-line camelcase
      const pythonDay = settings.first_day_of_week || '0';
      // Python has Monday == 0 and might store the day as a string
      // So force int conversion and add 1
      const firstDayOfWeek = (parseInt(pythonDay, 10) + 1) % 7;

      dispatch({
        type: configuration.types.setConfiguration,
        payload: {
          businessSlug,
          pageId,
          name,
          firstDayOfWeek,
          maxDaysInAdvance: settings?.max_days_in_advance ?? null,
          contactId: settings.resource_settings.registrant_type === 'contact'
            ? contactId ?? undefined
            : undefined,
          leadId: settings.resource_settings.registrant_type === 'lead'
            ? leadId ?? undefined
            : undefined,
		  accountId: settings.resource_settings.registrant_type === 'account'
            ? accountId ?? undefined
            : undefined,
          widgetButton: settings.widget_button,
          model: settings.reservation_model,
          logo: settings.header_settings.logo,
          defaultLanguage,
          languages: enabledLanguages,
          localeObjects: localeFnsObjects,
          showGroupSizeStep: settings.widget_settings?.show_group_size_step,
          showTermsStep: settings.widget_settings?.show_terms_and_conditions_step ?? true,
          showPayments: settings.widget_settings?.show_payments,
          paymentsEnabled: settings.widget_settings?.enable_payments,
          showNotes: settings.widget_settings?.show_notes,
          showLocation: settings.widget_settings?.show_location,
          showPriceInclVat: settings.widget_settings?.show_price_incl_vat ?? true,
          autoSelectBookableResource: settings.widget_settings?.auto_select_bookable_resource,
          showSelectedResourceStep: settings.widget_settings?.show_selected_resource_step ?? true,
          showServicesStep: settings.widget_settings?.show_services_step,
          apiOverride,
          showCompanyInputField: settings.resource_settings.use_company_field,
          userReservationTypes: (settings.reservation_type_settings || [])
            .filter((type) => type.enabled)
            .sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0)),
          currency: settings.widget_settings?.currency ?? 'EUR',
          calendarStartDate: settings.widget_settings?.calendar_start_date ?? null,
          calendarEndDate: settings.widget_settings?.calendar_end_date ?? null,
          enabledCalendarDays: (settings.widget_settings?.enabled_calendar_days ?? [
            '0', '1', '2', '3', '4', '5', '6',
          ]).map((day) => parseInt(day, 10)),
          customFields: settings.custom_fields,
          usePerAttendeeFields: settings.reservation_settings?.use_per_attendee_fields,
          perAttendeeFields: settings.per_attendee_fields,
        },
      });

      return {
        settings,
        name, ...props
      };
    },

    setWidgetOrigin: (parentOrigin) => async (dispatch) => {
      dispatch({
        type: configuration.types.setConfiguration,
        payload: {
          parentOrigin,
          showCloseButton: true,
        },
      });
    },
    updateConfiguration: ({ showCloseButton }) => async (dispatch) => {
      dispatch({
        type: configuration.types.setConfiguration,
        payload: { showCloseButton },
      });
    },
  });
}
