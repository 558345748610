import {
  Context,
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import dayjs, { Dayjs } from 'dayjs';

import useReduxKey from '../hooks/useReduxKey';
import { Calendar } from '../reducers/calendar';
import calendarActions, { CalendarActions } from '../actions/calendarActions';
import { FilterState } from '../reducers/filter';
import { Configuration } from '../reducers/configuration';
import { ResourceTreeNode } from '../hooks/useLocations';

interface CalendarApi extends Calendar, Omit<CalendarActions, 'loadCalendarAvailability'> {
  isFetchingFor: (date: Date) => boolean,
}

export const CalendarContext = createContext<Partial<CalendarApi>>({}) as Context<CalendarApi>;

interface CalendarProps {
  children: ReactNode,
}

const fetchLock = new Set();
export default function CalendarProvider({ children }: CalendarProps): JSX.Element {
  const {
    showGroupSizeStep
  } = useReduxKey<Configuration>('configuration');

  const {
    month,
    ...calendar
  } = useReduxKey<Calendar>('calendar');

  const {
    setSelectedDay, setMonth, loadCalendarAvailability, ...actions
  } = calendarActions();

  const [fetching, _setFetching] = useState(new Map<string, boolean>());
  const setFetching = useCallback((key: string, value: boolean) => {
    _setFetching((map) => new Map(map.set(key, value)));
  }, []);

  const { selectedLocation, startDate , groupSize, reservationType } = useReduxKey<FilterState>('filterState');

  const fetchCalendarAvailability = useCallback(async (_month: Dayjs, resources: string[], selectedLocation: ResourceTreeNode, reservationType?: string, groupSize?: number) => {
    const timezone = dayjs.tz.guess();

    const key = `${_month.format('YYYY-MM')}_${timezone}_${resources.join('-')}_${reservationType}_${groupSize}`;
    if (!fetchLock.has(key)) {
      try {
        fetchLock.add(key);
        setFetching(_month.format('YYYY-MM'), true);

        await loadCalendarAvailability(
          _month.startOf('month')
            .format('YYYY-MM-DD'),
          _month.endOf('month')
            .format('YYYY-MM-DD'),
          timezone,
          resources,
          reservationType,
          groupSize,
          selectedLocation,
          showGroupSizeStep,
        );

        setTimeout(() => fetchLock.delete(key), 60000);
      } catch (e) {
        fetchLock.delete(key);
      } finally {
        setFetching(_month.format('YYYY-MM'), false);
      }
    }
  }, []);

  useEffect(() => {
    if (month !== undefined && selectedLocation !== null && (groupSize !== null || !showGroupSizeStep)) {
      const dMonth = dayjs(month);
      const resources = selectedLocation.children.map(({ id }) => id);

      Promise.all([
        fetchCalendarAvailability(dMonth, resources, selectedLocation, reservationType, groupSize),
      ]);
    }
  }, [month, selectedLocation, groupSize, reservationType]);


  useEffect(() => {
    if (startDate !== null) {
      const newSelected = dayjs(startDate, 'YYYY-MM-DD').toDate();
      if (month !== undefined && month.getMonth() !== newSelected.getMonth()) {
        setMonth(newSelected);
      }

      setSelectedDay(newSelected);
    }
  }, [startDate]);

  const isFetchingFor = useCallback((date: Date): boolean => (
    fetching.get(dayjs(date).format('YYYY-MM')) ?? false
  ), [fetching]);

  const calendarApi: CalendarApi = {
    month,
    setMonth,
    setSelectedDay,
    isFetchingFor,
    ...calendar,
    ...actions,
  };

  return (
    <CalendarContext.Provider value={calendarApi}>
      {children}
    </CalendarContext.Provider>
  );
}
