import { useMemo, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import useReduxKey from '../../hooks/useReduxKey';

import DateTime from '../DateTime';
import StickyFooter from '../ReservationConfirmationFooter';
import ConfirmHeader from '../ConfirmHeader';
import Icon from '../../images/confirmation-black.svg';

import './styles.scss';
import { FilterState } from '../../reducers/filter';

export default (): ReactElement => {
  const filterState = useReduxKey<FilterState>('filterState');
  const {
    selectedSpace, selectedTimeSlot,
    startDate, reservationType, groupSize,
  } = filterState;
  const { form: { email } } = useReduxKey('details');
  const {
    showLocation, showGroupSizeStep, userReservationTypes, localeObjects
  } = useReduxKey('configuration');

  const { t, i18n } = useTranslation();

  const groupSizeLabel = groupSize === 1
    ? t('attendee') : t('attendees');

  const confirmationText = useMemo(() => {
    if (!email && t('confirmation_no_email')) {
      return t('confirmation_no_email');
    }
    const merged = t('confirmation_email', { email });
    if (merged.includes(email)) {
      return merged;
    }
    return `${merged} ${email}`;
  }, [email, t]);

  const reservationTypeName = useMemo(() => {
    // Only if user can choose
    if (!userReservationTypes.find((type) => type.enabled)) {
      return null;
    }
    const reservationType2 = userReservationTypes.find(
      (type) => type.reservation_type === reservationType,
    );
    return reservationType2?.name;
  }, [reservationType, userReservationTypes]);

  return (
    <>
      <ConfirmHeader title={t('confirmation')} />

      <div className="container-booking-overview">
        <h2>
          {t('booking_confirmed')}
          <img
            src={Icon}
            title="check"
            alt="check"
            className="confirmation-icon"
          />
        </h2>

        <p>
          {confirmationText}
        </p>

        <div className="card-summary">
          {selectedSpace.image && (
            <img
              src={selectedSpace.image}
              alt={selectedSpace.name}
              className="img-fit-cover"
            />
          )}
          <h3>{t('your_reservation')}</h3>
          <h2>{selectedSpace.name}</h2>
          {showLocation && <p>{selectedSpace.context}</p>}
          <ul>
            {showGroupSizeStep && (
              <li>{groupSize} {groupSizeLabel}</li>
            )}
            {reservationTypeName && (
              <li>{t(reservationTypeName.toLowerCase().replace(/ /g,"_"))}</li>
            )}
            <li>
              <DateTime datetime={startDate} format="date-long" localeObjects={localeObjects} locale={i18n.language} />
            </li>
            <li>
              <DateTime datetime={selectedTimeSlot.dStart} format="time" localeObjects={localeObjects} locale={i18n.language} />
              {' - '}
              <DateTime datetime={selectedTimeSlot.dEnd} format="time" localeObjects={localeObjects} locale={i18n.language} />
            </li>
          </ul>
        </div>
      </div>

      <StickyFooter />
    </>
  );
};
