import dayjs from 'dayjs';
import useActions from '../hooks/useActions';
import useBooker25 from '../hooks/useBooker25';
import timeslots from '../reducers/timeslots';
import { ResourceTreeNode } from '../hooks/useLocations';
import Booker25, { TimeslotContent } from '../services/Booker25';
import useReduxKey from '../hooks/useReduxKey';
import AsyncCache from '../utils/asyncCache';

interface FetchTimeslotProps {
  api: Booker25;
  selectedDate: string;
  selectedLocation: ResourceTreeNode;
}

interface FetchCapacityBasedTimeSlotsProps extends FetchTimeslotProps {
  selectedGroupSize: number;
}

const fetchCapacityBasedTimeSlots = async ({ api, selectedDate, selectedGroupSize, selectedLocation }: FetchCapacityBasedTimeSlotsProps) => {
  const ids = selectedLocation.children.map(({ id }) => id);
  // @ts-ignore
  const { reservations } = await api.getWidgetReservations(selectedDate, selectedGroupSize, ids);

  return reservations.map((timeslotReservation) => ({
    available: true,
    id: timeslotReservation.id,
    resource_id: timeslotReservation.resource_id,
    start: timeslotReservation.start_gmt,
    end: timeslotReservation.end_gmt,
    info: timeslotReservation.info,
    model: 'capacity',
    quantity: timeslotReservation.quantity,
    max_capacity: timeslotReservation.max_capacity,
  }));
};

interface FetchAvailabilityBasedTimeslotsProps extends FetchTimeslotProps{
  reservationType: string;
  showGroupSizeStep: boolean;
  selectedGroupSize: number;
}

export const mapTimeslot = (selectedLocation: ResourceTreeNode, showGroupSizeStep: boolean, selectedGroupSize: number) => (timeslot: TimeslotContent) => {
  const resources = selectedLocation.children.reduce((obj, resource) => ({
    ...obj,
    [resource.id]: resource,
  }), {});

  const ts = {
    quantity: 0,
    max_capacity: resources[timeslot.resource].max_capacity && showGroupSizeStep
      ? resources[timeslot.resource].max_capacity
      : Infinity,
    info: '',
    available: timeslot.available,
    start: timeslot.start.replace('T', ' '),
    end: timeslot.end.replace('T', ' '),
    model: 'availability',
    // We supply a fake id, because the interface needs to be able
    // to distinguish different timeslots easily.
    id: `fake-${timeslot.resource}-${timeslot.start}-${timeslot.end}`,
    resource_id: timeslot.resource,
  }

  ts.available = ts.available && (
    ts.max_capacity >= selectedGroupSize
    || ts.max_capacity === 0
    || ts.max_capacity === null
  )

  return ts
}

const fetchAvailabilityBasedTimeslots = async ({
  api,
  selectedDate,
  selectedLocation,
  reservationType,
  showGroupSizeStep,
  selectedGroupSize,
}: FetchAvailabilityBasedTimeslotsProps) => {
  const resources = selectedLocation.children.reduce((obj, resource) => ({
    ...obj,
    [resource.id]: resource,
  }), {});
  const selectedDay = dayjs(selectedDate);
  const start = selectedDay.startOf('day');
  const end = start.add(1, 'day');

  const slots = await api.getTimeslots(
    start.toISOString(),
    end.toISOString(),
    Object.keys(resources),
    reservationType,
    selectedGroupSize,
  );

  return Object
    .values(slots)
    .flat()
    .map(mapTimeslot(selectedLocation, showGroupSizeStep, selectedGroupSize));
};

const cache = new AsyncCache();

export default () => {
  const api = useBooker25();
  const { showGroupSizeStep, model = 'capacity' } = useReduxKey('configuration');
  const { timeslotCacheVersion } = useReduxKey('timeslots');
  const cacheKeyPrefix = `${api.pageId}/${timeslotCacheVersion}`;
  return useActions({
    fetchCachedTimeslots: (
      selectedLocation: ResourceTreeNode,
      selectedGroupSize: number,
      selectedDate: string,
      reservationType: string = null,
    ) => (dispatch) => {
      const baseKey = `${cacheKeyPrefix}/${selectedDate}/${selectedLocation.id}/${selectedGroupSize}`;
      const cacheKey = model === 'capacity' ? `${baseKey}/capacity` : `${baseKey}/${reservationType || '-'}`;
      const payload = cache.get(cacheKey);
      if (payload) {
        dispatch({
          type: timeslots.types.setTimeslots,
          payload,
        });
      }
      return payload;
    },
    fetchTimeslots: (
      selectedLocation: ResourceTreeNode,
      selectedGroupSize: number,
      selectedDate: string,
      reservationType: string = null,
    ) => async (dispatch) => {
      const baseKey = `${cacheKeyPrefix}/${selectedDate}/${selectedLocation.id}/${selectedGroupSize}`;
      if (model === 'capacity') {
        const cacheKey = `${baseKey}/capacity`;
        const payload = await cache.remember(cacheKey, 1000 * 60 * 60,
          async () => fetchCapacityBasedTimeSlots({
            api,
            selectedLocation,
            selectedDate,
            selectedGroupSize,
          }));
        dispatch({
          type: timeslots.types.setTimeslots,
          payload,
        });
      } else {
        const cacheKey = `${baseKey}/${reservationType || '-'}`;
        const payload = await cache.remember(cacheKey, 1000 * 60 * 60,
          async () => fetchAvailabilityBasedTimeslots({
            api,
            selectedLocation,
            selectedDate,
            reservationType,
            showGroupSizeStep,
            selectedGroupSize,
          }));
        dispatch({
          type: timeslots.types.setTimeslots,
          payload,
        });
      }
    },
  });
};
